import { api } from ".";
import * as Actions from "../redux/constants/Auth";

const solicitationService = {};

const config = () => {
  const token = localStorage.getItem(Actions.AUTH_TOKEN);

  const config = { headers: {} };

  if (token) {
    config.headers = { Authorization: token };
  }

  return config;
};

solicitationService.startSolicitation = async function (isBndes) {
  let result = null;

  let body = { project: "perola" };
  if (isBndes) {
    body = {
      project: "perola",
      isBndes: true,
    };
  }

  await api
    .post("/api/v1/solicitations", body)
    .then(function (res) {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch(function (error) {
      console.error(error);
      result = { data: null, error: true, type: error };
    });
  return result;
};

solicitationService.postSolicitation = async function (data) {
  let result = null;
  await api
    .post(`/api/solicitations`, data)
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      console.log("error", error);
      result = { data: null, error: true, type: error };
    });
  return result;
};

solicitationService.completeSolicitation = async function (
  data,
  solicitationToken
) {
  let result = null;

  const requestConfig = config();
  requestConfig.headers["x-solicitation-token"] = solicitationToken;

  await api
    .post(`/api/v1/solicitations/current/completion`, data, requestConfig)
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      console.log("error", error);
      result = { data: null, error: true, type: error };
    });
  return result;
};

solicitationService.getFind = async function () {
  let result = null;
  await api
    .get(`/api/how-did-find/all`)
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      console.log("error", error);
      result = { data: null, error: true, type: error };
    });
  return result;
};

solicitationService.getUserSolicitations = async function (id) {
  let result = null;
  await api
    .get(`/api/solicitations/user/${id}`, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      console.log("error", error);
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

solicitationService.getUserSolicitationsDoc = async function (id) {
  let result = null;
  await api
    .get(`/api/solicitations/user/doc/${id}`, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      console.log("error", error);
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

solicitationService.getSolicitationById = async function (id) {
  let result = null;
  await api
    .get(`/api/solicitations/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      console.log("error", error);
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status ? error.response.status : null,
      };
    });
  return result;
};

solicitationService.uploadDocument = async function (
  data,
  documentType,
  solicitationToken
) {
  let result = null;
  await api
    .put(`/api/v1/solicitations/current/documents/${documentType}`, data, {
      headers: {
        "x-solicitation-token": solicitationToken,
      },
    })
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      console.log("uploadDocument error :::", error);
      result = { data: null, error: true, type: error };
    });
  return result;
};

solicitationService.updateDocument = async function (
  solicitationId,
  data,
  documentType
) {
  let result = null;
  await api
    .put(
      `/api/v1/solicitations/${solicitationId}/documents/${documentType}`,
      data,
      config()
    )
    .then((res) => {
      result = { data: res.data, error: false, type: res.status };
    })
    .catch((error) => {
      console.log("uploadDocument error :::", error);
      result = { data: null, error: true, type: error };
    });
  return result;
};

solicitationService.getContracts = async function (id) {
  let result = null;
  await api
    .get(`/api/company/contracts/active/${id}`, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      console.log("error", error);
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status ? error.response.status : null,
      };
    });
  return result;
};
solicitationService.getContractMPO = async function (id) {
  let result = null;
  let url = "";

  url = `/api/creditOperation/${id}/contract/mpo`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

solicitationService.getSolicitationBndes = async function (proposalId) {
  let result = null;
  await api
    .get(`/api/v1/solicitations/form/bndes/${proposalId}`, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      console.log("error", error);
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status ? error.response.status : null,
      };
    });
  return result;
};
export default solicitationService;
